import { gql, useMutation } from '@apollo/client';
import { Button, Toast, AlertDialog } from 'native-base';
import React from 'react';

import { useAuth } from '~/providers/AuthProvider';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

export default function DeleteAccountDialog() {
  const { removeAuthData } = useAuth();

  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => setIsOpen(false);

  const cancelRef = React.useRef(null);

  const [deleteCurrentMemberAccount, { loading: deleting }] = useMutation(
    gql`
      mutation DeleteCurrentMemberAccount($input: DeleteCurrentMemberAccountInput!) {
        deleteCurrentMemberAccount(input: $input) {
          success
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.deleteCurrentMemberAccount.success) {
          removeAuthData();
          Toast.show({ title: 'Account deleted successfully', status: 'success' });
        } else {
          const errors = result.deleteCurrentMemberAccount.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
    }
  );

  return (
    <>
      <Button
        {...{
          variant: 'outline',
          _hover: { bg: 'transparent' },
          _pressed: { bg: 'transparent' },
          _focus: { bg: 'transparent' },
          _focusVisible: { bg: 'transparent' },
        }}
        onPress={() => setIsOpen(true)}
        disabled={deleting}
        isLoading={deleting}
      >
        {'Delete account'}
      </Button>

      <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{'Delete account'}</AlertDialog.Header>
          <AlertDialog.Body>{'This will delete your member account.'}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                variant={'unstyled'}
                colorScheme={'coolGray'}
                onPress={onClose}
                ref={cancelRef}
              >
                {'Cancel'}
              </Button>
              <Button
                colorScheme={'danger'}
                onPress={() =>
                  deleteCurrentMemberAccount({
                    variables: {
                      input: {},
                    },
                  })
                }
              >
                {'Delete'}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}
