import Constants from 'expo-constants';
import { Platform } from 'react-native';

import version from './version';

const { manifest2 } = Constants;

export default {
  apiUrl:
    Platform.OS === 'android' || Platform.OS === 'ios'
      ? `http://${manifest2?.extra?.expoGo?.debuggerHost?.split(`:`).shift()?.concat(`:3210`)}`
      : 'http://localhost:3210',
  oAuthClientId: '-ykXiqqhTL-CGc1_x5mp_XCRWyn59BF1XtmAH0gStV8',
  sentryDsn: 'https://b7ac2b3094a44d34b35d314c22cd0f47@o1091418.ingest.sentry.io/6108853',
  sentryDebug: true,
  sentryEnvironment: 'default',
  version,
};
