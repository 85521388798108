import { gql, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { VStack, FormControl, Input, Button, Toast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import DeleteAccountDialog from './DeleteAccountDialog';

import ScreenBackground from '~/components/ScreenBackground';
import yup from '~/lib/yup';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

const schema = yup.object().shape({
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().required().email().label('Email'),
  password: yup
    .string()
    .matches(/\S{6,}/, {
      message: 'Password must be at least 6 characters',
      excludeEmptyString: true,
    })
    .label('Password'),
});

type FormShape = {
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
};

export default function AccountScreen() {
  // Prevent crashing on Xiaomi devices
  // https://github.com/facebook/react-native/issues/27204#issuecomment-902820636
  const [caretHidden, setCaretHidden] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormShape>({
    resolver: yupResolver(schema),
  });

  const { loading } = useQuery(
    gql`
      query GetCurrentMemberAccount {
        currentMemberAccount {
          id
          name
          phoneNumber
          email
        }
      }
    `,
    {
      onCompleted: data => {
        const {
          currentMemberAccount: { name, phoneNumber, email },
        } = data;

        reset({ name, phoneNumber, email });
      },
    }
  );

  const [updateCurrentMemberAccount, { loading: updating }] = useMutation(
    gql`
      mutation UpdateCurrentMemberAccount($input: UpdateCurrentMemberAccountInput!) {
        updateCurrentMemberAccount(input: $input) {
          currentMemberAccount {
            id
            name
            phoneNumber
            email
          }
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.updateCurrentMemberAccount.currentMemberAccount) {
          Toast.show({ title: 'Account updated successfully', status: 'success' });
        } else {
          const errors = result.updateCurrentMemberAccount.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
      refetchQueries: ['GetCurrentMemberAccount'],
    }
  );

  const handleUpdate = async (values: FormShape) => {
    await updateCurrentMemberAccount({
      variables: {
        input: {
          ...values,
        },
      },
    });
  };

  useEffect(() => {
    setCaretHidden(false);
  }, []);

  return (
    <ScreenBackground safeAreaBottom>
      <VStack flex={1} justifyContent={'space-between'}>
        <VStack flex={1} space={4}>
          <FormControl isInvalid={!!errors.name}>
            <FormControl.Label>{'Name'}</FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input onBlur={onBlur} onChangeText={onChange} value={value} ref={ref} />
              )}
              name={'name'}
              defaultValue={''}
            />
            <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.phoneNumber}>
            <FormControl.Label>{'Phone'}</FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <NumberFormat
                  value={value}
                  thousandsGroupStyle={'wan'}
                  thousandSeparator={' '}
                  allowLeadingZeros
                  decimalScale={0}
                  displayType={'text'}
                  renderText={formattedValue => (
                    <Input
                      keyboardType={'phone-pad'}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={formattedValue}
                      ref={ref}
                    />
                  )}
                />
              )}
              name={'phoneNumber'}
              defaultValue={''}
            />
            <FormControl.ErrorMessage>{errors.phoneNumber?.message}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.email}>
            <FormControl.Label>{'Email'}</FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  caretHidden={caretHidden}
                  keyboardType={'email-address'}
                  autoCapitalize={'none'}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  ref={ref}
                />
              )}
              name={'email'}
              defaultValue={''}
            />
            <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <FormControl.Label>{'New password'}</FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  autoComplete={'off'}
                  autoCapitalize={'none'}
                  type={'password'}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  ref={ref}
                />
              )}
              name={'password'}
              defaultValue={''}
            />
            <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>
          </FormControl>

          <Button
            mt={2}
            disabled={loading}
            isLoading={updating}
            onPress={handleSubmit(handleUpdate)}
          >
            {'Update'}
          </Button>
        </VStack>

        <DeleteAccountDialog />
      </VStack>
    </ScreenBackground>
  );
}
