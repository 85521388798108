import * as Updates from 'expo-updates';

import defaultConfig from './default';
import development from './development';
import production from './production';

const getConfig = () => {
  const env = process.env.APP_ENV || Updates.channel || 'default';

  if (env.toLowerCase().startsWith('prod')) {
    return production;
  } else if (env.toLowerCase().startsWith('dev')) {
    return development;
  } else {
    return defaultConfig;
  }
};

export default getConfig();
