import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as AP,
  HttpLink,
  from,
  ServerError,
  ApolloError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { getAccessToken, useAuth } from './AuthProvider';

import config from '~/config';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

const ApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const { gymId, removeToken } = useAuth();
  const [sentryLink, setSentryLink] = useState<any[]>([]);

  const graphqlUri = useMemo(() => `${config.apiUrl}/member_graphql`, []);

  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: graphqlUri,
      }),
    [graphqlUri]
  );

  const authLink = useMemo(
    () =>
      setContext(async (_, { headers }) => {
        const token = await getAccessToken();
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            ...(token && { Authorization: `Bearer ${token}` }),
            ...(gymId && { 'X-Gym-Id': gymId }),
          },
        };
      }),
    [gymId]
  );

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError }) => {
        Sentry.captureException(new ApolloError({ graphQLErrors, networkError }));

        // if (graphQLErrors)
        //   graphQLErrors.forEach(({ message, locations, path }) =>
        //     console.error(
        //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        //     )
        //   );

        if (networkError) {
          // console.error(`[Network error]: ${networkError}`);

          showErrorMessage((networkError as ServerError).result);

          if ((networkError as ServerError).statusCode === 401) {
            removeToken();
          }
        }
      }),
    [removeToken]
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([...sentryLink, errorLink, authLink, httpLink]),
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                bookings: relayStylePagination(),
                schedules: relayStylePagination(),
                transactions: relayStylePagination(),
                trainers: relayStylePagination(),
                sessions: relayStylePagination(),
                notifications: relayStylePagination(),
              },
            },
          },
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
          },
        },
      }),
    [authLink, errorLink, httpLink, sentryLink]
  );

  useEffect(() => {
    (async () => {
      const sentryLink = [];

      if (Platform.OS !== 'web') {
        const { SentryLink } = await import('apollo-link-sentry');

        sentryLink.push(
          new SentryLink({
            uri: graphqlUri,
            attachBreadcrumbs: {
              includeQuery: true,
              includeFetchResult: true,
              includeError: true,
            },
          })
        );

        setSentryLink(sentryLink);
      }
    })();
  }, [graphqlUri]);

  return <AP client={client}>{children}</AP>;
};

export default ApolloProvider;
