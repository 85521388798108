import { VStack, FormControl } from 'native-base';
import React from 'react';

import ScreenBackground from '~/components/ScreenBackground';
import ToggleDarkMode from '~/components/ToggleDarkMode';

export default function SettingsScreen() {
  return (
    <ScreenBackground safeAreaBottom>
      <VStack flex={1} space={4}>
        <FormControl>
          <FormControl.Label>{'Theme'}</FormControl.Label>
          <ToggleDarkMode />
        </FormControl>
      </VStack>
    </ScreenBackground>
  );
}
