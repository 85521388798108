import { useLinkTo } from '@react-navigation/native';
import * as Application from 'expo-application';
import { VStack, Button, Text, Pressable, ChevronRightIcon, HStack } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';

import app from '~/app.json';
import ScreenBackground from '~/components/ScreenBackground';
import config from '~/config';
import ProfilePhotoContainer from '~/containers/ProfilePhotoContainer';
import { useAuth } from '~/providers/AuthProvider';

export default function ProfileScreen() {
  const mountedRef = useRef(false);
  const [signingOut, setSigningOut] = useState(false);
  const { signOut } = useAuth();
  const linkTo = useLinkTo();

  const handleSignOut = async () => {
    setSigningOut(true);
    await signOut();
    mountedRef.current && setSigningOut(false);
  };

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <ScreenBackground p={4}>
      <VStack flex={1} justifyContent={'space-between'}>
        <VStack space={4}>
          <ProfilePhotoContainer />

          <Pressable onPress={() => linkTo('/account')}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <Text>{'Account'}</Text>
              <ChevronRightIcon size={'sm'} />
            </HStack>
          </Pressable>

          <Pressable onPress={() => linkTo('/transactions')}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <Text>{'Transactions'}</Text>
              <ChevronRightIcon size={'sm'} />
            </HStack>
          </Pressable>

          <Pressable onPress={() => linkTo('/about-gym')}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <Text>{'About gym'}</Text>
              <ChevronRightIcon size={'sm'} />
            </HStack>
          </Pressable>

          <Pressable onPress={() => linkTo('/settings')}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <Text>{'Settings'}</Text>
              <ChevronRightIcon size={'sm'} />
            </HStack>
          </Pressable>
        </VStack>

        <VStack space={2}>
          <Text selectable>{`Version: ${
            Application.nativeApplicationVersion || app.expo.version || 'latest'
          }-${config.version}`}</Text>
          <Button mt={'2'} isLoading={signingOut} onPress={handleSignOut}>
            {'Sign out'}
          </Button>
        </VStack>
      </VStack>
    </ScreenBackground>
  );
}
