/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          SignIn: 'sign-in',
          SignUp: 'sign-up',
          ResetPassword: 'reset-password',
          UpdatePassword: 'update-password',
          SelectGym: 'select-gym',
          Main: {
            screens: {
              Home: {
                screens: {
                  Timetable: 'timetable',
                  Membership: 'membership',
                  Activity: 'activity',
                  Profile: 'profile',
                },
              },
              CheckIn: '/check-in',
              Notifications: '/notifications',
              TrainerSchedules: '/trainer-schedules',
              ClassSchedule: '/class-schedule',
              TrainerSchedule: '/trainer-schedule',
              MidtransSnap: '/midtrans-snap',
              Account: '/account',
              Transactions: '/transactions',
              AboutGym: '/about-gym',
              Settings: '/settings',
            },
          },
        },
      },
      Modal: 'modal',
      NotFound: '*',
    },
  },
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    let url = await Linking.getInitialURL();

    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    url = response?.notification.request.content.data.url as string;

    return url;
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    // Listen to incoming links from deep linking
    const addedListener = Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(response => {
      const url = response.notification.request.content.data.url as string;

      // Any custom logic to see whether the URL needs to be handled
      //...

      // Let React Navigation handle the URL
      listener(url);
    });

    return () => {
      // Clean up the event listeners
      addedListener.remove();
      subscription.remove();
    };
  },
};

export default linking;
