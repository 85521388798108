import { yupResolver } from '@hookform/resolvers/yup';
import { Text, Heading, VStack, FormControl, Input, Button, HStack, Box } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import LinkNavigation from '~/components/LinkNavigation';
import ScreenBackground from '~/components/ScreenBackground';
import yup from '~/lib/yup';
import { useAuth } from '~/providers/AuthProvider';

const schema = yup.object().shape({
  email: yup.string().required().email().label('Email'),
  password: yup.string().required().min(6),
});

export default function SignInScreen() {
  const mountedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ email: string; password: string }>({ resolver: yupResolver(schema) });

  // Prevent crashing on Xiaomi devices
  // https://github.com/facebook/react-native/issues/27204#issuecomment-902820636
  const [caretHidden, setCaretHidden] = useState(true);

  const handleSignIn = async (values: Parameters<typeof signIn>[0]) => {
    setLoading(true);
    await signIn({ ...values });
    mountedRef.current && setLoading(false);
  };

  useEffect(() => {
    mountedRef.current = true;
    setCaretHidden(false);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <ScreenBackground safeArea px={'5%'} py={'10'}>
      <Heading size={'lg'} fontWeight={'600'}>
        {'Welcome'}
      </Heading>
      <Heading mt={'1'} fontWeight={'medium'} size={'xs'}>
        {'Sign in to continue'}
      </Heading>

      <VStack space={3} mt={'5'}>
        <FormControl isInvalid={!!errors.email}>
          <FormControl.Label>{'Email'}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                caretHidden={caretHidden}
                keyboardType={'email-address'}
                autoCompleteType={'email'}
                textContentType={'emailAddress'}
                autoCapitalize={'none'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                ref={ref}
              />
            )}
            name={'email'}
            defaultValue={''}
          />
          <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.password}>
          <FormControl.Label>{'Password'}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input
                type={'password'}
                autoCompleteType={'password'}
                textContentType={'password'}
                autoCapitalize={'none'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                ref={ref}
              />
            )}
            name={'password'}
            defaultValue={''}
          />
          <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>

          <Box alignSelf={'flex-end'} mt={'1'}>
            <LinkNavigation to={'/reset-password'}>{'Forgot password?'}</LinkNavigation>
          </Box>
        </FormControl>

        <Button mt={'2'} isLoading={loading} onPress={handleSubmit(handleSignIn)}>
          {'Sign in'}
        </Button>

        <HStack mt={'6'} justifyContent={'center'}>
          <Text>{"I'm a new user. "}</Text>
          <LinkNavigation to={'/sign-up'}>{'Sign up'}</LinkNavigation>
        </HStack>
      </VStack>
    </ScreenBackground>
  );
}
