import { CaptureContext, Severity } from '@sentry/types';

import { captureMessage } from './sentry';

export * from './sentry';

export const captureAnyMessage = (
  message: any,
  captureContext?: CaptureContext | Severity | undefined
) => {
  captureMessage(JSON.stringify(message, null, 2), captureContext);
};
