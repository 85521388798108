import { useLinkTo } from '@react-navigation/native';
import { format, formatDistanceToNowStrict } from 'date-fns';
import {
  Box,
  ChevronRightIcon,
  Flex,
  HStack,
  Pressable,
  Text,
  VStack,
  useColorModeValue,
  useToken,
} from 'native-base';
import React, { FC } from 'react';
import { Dimensions, Platform } from 'react-native';

import Card from '~/components/Card';
import { useMobileWebStyle } from '~/navigation';

const keyToUnit = {
  xSeconds: 's',
  xMinutes: 'm',
  xHours: 'h',
  xDays: 'd',
  xMonths: 'mo',
  xYears: 'y',
};

const localeFormatDistance = (key: keyof typeof keyToUnit, value: number) => {
  return `${value}${keyToUnit[key]}`;
};

const WaitingListNotificationItem: FC<{ item: any }> = ({ item }) => {
  const webStyle = useMobileWebStyle();
  const linkTo = useLinkTo();

  const [primary500, primary300] = useToken('colors', ['primary.500', 'primary.300']);
  const primaryColor = useColorModeValue(primary500, primary300);

  const schedule = item?.waitingListNotificationParams?.booking?.schedule;
  const deleted = !schedule;

  return (
    <Pressable
      disabled={deleted}
      onPress={() =>
        linkTo({
          // @ts-ignore
          screen: 'ClassSchedule',
          // @ts-ignore
          params: { scheduleId: schedule.id },
        })
      }
    >
      <HStack alignItems={'center'}>
        <Box bg={!item.readAt ? primaryColor : null} rounded={'full'} height={2} width={2} mx={1} />

        <Card
          style={{
            padding: 16,
            borderRadius: 8,
            width:
              Platform.OS === 'web'
                ? Math.min(webStyle.maxWidth as number, Dimensions.get('window').width) - 32
                : Dimensions.get('window').width - 32,
          }}
        >
          <VStack>
            <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Box flex={1}>
                <Text>
                  {'Someone canceled their booking. You are now on the booking list of '}
                  <Text fontWeight={'bold'}>
                    {!deleted
                      ? `${schedule.gymClass.name}, ${format(
                          new Date(schedule.startedAt),
                          'EEE dd MMM yyyy, HH:mm'
                        )} - ${format(new Date(schedule.endedAt), 'HH:mm')}`
                      : '[Deleted Booking / Schedule]'}
                  </Text>
                </Text>
              </Box>

              <VStack marginLeft={8}>
                <Text fontSize={'xs'} minWidth={6}>
                  {formatDistanceToNowStrict(new Date(item.createdAt), {
                    locale: { formatDistance: localeFormatDistance },
                  })}
                </Text>

                {!deleted && <ChevronRightIcon size={'sm'} />}
              </VStack>
            </Flex>

            <HStack justifyContent={'flex-end'} />
          </VStack>
        </Card>
      </HStack>
    </Pressable>
  );
};

const WillExpireTransactionNotificationItem: FC<{ item: any }> = ({ item }) => {
  const webStyle = useMobileWebStyle();
  const linkTo = useLinkTo();

  const [primary500, primary300] = useToken('colors', ['primary.500', 'primary.300']);
  const primaryColor = useColorModeValue(primary500, primary300);

  const transaction = item?.willExpireTransactionNotificationParams?.transaction;
  const membershipPackage = transaction?.membershipPackage;
  const membershipName = membershipPackage?.membership?.name;
  const membershipPackageName = membershipPackage?.name;

  const deleted = !membershipPackage;

  const transactionName = `${membershipName}: ${membershipPackageName}`;

  return (
    <Pressable
      disabled={deleted}
      onPress={() =>
        linkTo({
          screen: 'Root',
          params: {
            screen: 'Main',
            params: {
              screen: 'Home',
              params: {
                screen: 'Membership',
              },
            },
          },
        })
      }
    >
      <HStack alignItems={'center'}>
        <Box bg={!item.readAt ? primaryColor : null} rounded={'full'} height={2} width={2} mx={1} />

        <Card
          style={{
            padding: 16,
            borderRadius: 8,
            width:
              Platform.OS === 'web'
                ? Math.min(webStyle.maxWidth as number, Dimensions.get('window').width) - 32
                : Dimensions.get('window').width - 32,
          }}
        >
          <VStack>
            <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Box flex={1}>
                <Text>
                  {'Your '}
                  <Text fontWeight={'bold'}>
                    {!deleted ? transactionName : '[Deleted Transaction / Membership]'}
                  </Text>
                  {' membership will expire in 3 days. Extend now.'}
                </Text>
              </Box>

              <VStack marginLeft={8}>
                <Text fontSize={'xs'} minWidth={6}>
                  {formatDistanceToNowStrict(new Date(item.createdAt), {
                    locale: { formatDistance: localeFormatDistance },
                  })}
                </Text>

                {!deleted && <ChevronRightIcon size={'sm'} />}
              </VStack>
            </Flex>

            <HStack justifyContent={'flex-end'} />
          </VStack>
        </Card>
      </HStack>
    </Pressable>
  );
};

const NotificationItem: FC<{ item: any; onPress: (item: any) => void }> = ({ item }) => {
  if (item.type === 'WaitingListNotification') {
    return <WaitingListNotificationItem item={item} />;
  } else if (item.type === 'WillExpireTransactionNotification') {
    return <WillExpireTransactionNotificationItem item={item} />;
  }

  return <></>;
};

export default NotificationItem;
