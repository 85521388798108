import * as yup from 'yup';

const requiredErrorMessage = 'Should not be empty';

yup.setLocale({
  mixed: {
    required: requiredErrorMessage,
    notType: requiredErrorMessage,
    oneOf: requiredErrorMessage,
  },
  number: {
    min: requiredErrorMessage,
  },
  string: {
    matches: requiredErrorMessage,
  },
});

export default yup;
