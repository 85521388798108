import { Box, IBoxProps } from 'native-base';
import React, { FC } from 'react';

const ScreenBackground: FC<IBoxProps> = ({ children, ...props }) => {
  return (
    <Box
      p={4}
      flex={1}
      _light={{ bg: 'light.100', _text: { color: 'black' } }}
      _dark={{ bg: 'dark.50', _text: { color: 'gray.300' } }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ScreenBackground;
