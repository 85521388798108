import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { merge } from 'lodash';
import { ITheme } from 'native-base';
import React, { useState } from 'react';

import useColorModeManager from './useColorModeManager';

import { theme as defaultTheme } from '~/constants/Theme';
import * as Sentry from '~/utils/sentry';

export default function useCachedResources() {
  const [state, setState] = useState<{ loading: boolean; theme: ITheme | undefined }>({
    loading: true,
    theme: undefined,
  });

  const colorModeManager = useColorModeManager();

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      let theme = undefined;

      try {
        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
        });

        // Load initial color mode
        const initialColorMode = await colorModeManager.get();
        theme = merge(defaultTheme, {
          config: {
            initialColorMode,
          },
        });
      } catch (e) {
        Sentry.captureException(e);
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setState({ loading: false, theme });
      }
    }

    loadResourcesAndDataAsync();
  }, [colorModeManager]);

  return state;
}
