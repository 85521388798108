import { differenceInDays, format } from 'date-fns';
import { Heading, HStack, Pressable, Text, VStack } from 'native-base';
import React, { FC } from 'react';
import { Dimensions, Platform } from 'react-native';

import Card from '~/components/Card';
import { useMobileWebStyle } from '~/navigation';
import { formatCurrency } from '~/utils/numberUtil';

const TransactionItem: FC<{ item: any; onPress: (item: any) => void }> = ({ item, onPress }) => {
  const webStyle = useMobileWebStyle();

  const periodDay =
    item.periodDay || differenceInDays(new Date(item.endedAt), new Date(item.startedAt));

  return (
    <Pressable onPress={() => onPress(item)}>
      <Card
        style={{
          padding: 16,
          borderRadius: 8,
          width:
            Platform.OS === 'web'
              ? Math.min(webStyle.maxWidth as number, Dimensions.get('window').width) - 32
              : Dimensions.get('window').width - 32,
        }}
      >
        <VStack>
          <HStack justifyContent={'space-between'}>
            <Heading size={'md'}>{item.membershipName}</Heading>

            <VStack space={2} alignItems={'flex-end'}>
              <Heading size={'md'}>{formatCurrency(item.price)}</Heading>
              <Text>{`${periodDay} Days`}</Text>
            </VStack>
          </HStack>

          <HStack justifyContent={'space-between'}>
            {/* @ts-ignore */}
            <Text>{`${item.unlimitedSession ? '∞' : item.trainingSession} Credits, ${
              item.bookingUsed
            } Used`}</Text>
            <Text>{format(new Date(item.startedAt), 'dd MMM yyyy')}</Text>
          </HStack>
        </VStack>
      </Card>
    </Pressable>
  );
};

export default TransactionItem;
