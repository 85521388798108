import { gql, useMutation, useQuery } from '@apollo/client';
import { StackScreenProps } from '@react-navigation/stack';
import { format } from 'date-fns';
import {
  VStack,
  Button,
  Text,
  Heading,
  Divider,
  Toast,
  Skeleton,
  HStack,
  Pressable,
  Actionsheet,
  useDisclose,
  useToken,
  useColorModeValue,
  ScrollView,
} from 'native-base';
import React, { FC, useMemo } from 'react';

import { MainStackParamList } from '..';

import Card from '~/components/Card';
import FontAwesomeIcon from '~/components/FontAwesomeIcon';
import ScreenBackground from '~/components/ScreenBackground';
import { useMobileWebStyle } from '~/navigation';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

const ClassScheduleScreen: FC<StackScreenProps<MainStackParamList, 'ClassSchedule'>> = ({
  route,
}) => {
  const { isOpen, onOpen, onClose } = useDisclose();
  const mobileWebStyle = useMobileWebStyle();
  const { scheduleId } = route.params;
  const [lightText, darkText] = useToken('colors', ['lightText', 'darkText']);
  const textColor = useColorModeValue(darkText, lightText);

  const { loading, data = { schedule: { gymClass: { trainer: {} }, bookings: { edges: [] } } } } =
    useQuery(
      gql`
        query GetSchedule($id: ID!) {
          schedule(id: $id) {
            id
            startedAt
            endedAt
            gymClass {
              id
              name
              trainer {
                id
                name
              }
              capacity
            }
            bookingCount
            booking {
              id
              waitingList
            }
            bookings {
              edges {
                node {
                  id
                  waitingList
                  transaction {
                    member {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        variables: {
          id: scheduleId,
        },
      }
    );

  const [createBooking, { loading: isBooking }] = useMutation(
    gql`
      mutation CreateBookingMutation($input: CreateBookingInput!) {
        createBooking(input: $input) {
          booking {
            waitingList
            id
            schedule {
              id
            }
            transaction {
              id
            }
          }
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.createBooking.booking) {
          if (!result.createBooking.booking.waitingList) {
            Toast.show({ title: 'Booking created', status: 'success' });
          } else {
            Toast.show({ title: 'Booking queued to waiting list', status: 'warning' });
          }
        } else {
          const errors = result.createBooking.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
      refetchQueries: ['GetSchedule', 'GetBookings'],
    }
  );

  const handleBook = async () => {
    await createBooking({
      variables: {
        input: {
          scheduleId,
        },
      },
    });
  };

  const [deleteBooking, { loading: deleting }] = useMutation(
    gql`
      mutation DeleteBookingMutation($input: DeleteBookingInput!) {
        deleteBooking(input: $input) {
          success
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.deleteBooking.success) {
          Toast.show({ title: 'Booking deleted successfuly', status: 'success' });
        } else {
          const errors = result.deleteBooking.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
      refetchQueries: ['GetSchedule', 'GetBookings'],
    }
  );

  const handleCancelBook = async () => {
    await deleteBooking({
      variables: {
        input: {
          id: data.schedule.booking?.id,
        },
      },
    });
    onClose();
  };

  const passed =
    data.schedule.startedAt && new Date(data.schedule.startedAt).getTime() <= new Date().getTime();
  const { booking } = data.schedule;
  const booked = !!booking;
  const disabledBook = loading || booked || passed;
  const bookLabel = booked
    ? booking?.waitingList
      ? 'Waiting List'
      : 'Booked'
    : passed
    ? 'Passed'
    : 'Book';

  const [bookingList, waitingList] = useMemo(() => {
    const booked: any[] = [];
    const waiting: any[] = [];
    data.schedule.bookings.edges.forEach(({ node: booking }: any) => {
      if (booking.waitingList) {
        waiting.push(booking);
      } else {
        booked.push(booking);
      }
    });

    return [booked, waiting];
  }, [data.schedule.bookings.edges]);

  return (
    <ScreenBackground safeAreaBottom>
      <VStack justifyContent={'space-between'} flex={1}>
        {/* <ScrollView> */}
        <VStack flex={1} space={3}>
          {loading ? (
            <Skeleton width={64} height={6} />
          ) : (
            <Heading>{data.schedule.gymClass.name}</Heading>
          )}

          {loading ? (
            <Skeleton width={16} height={4} />
          ) : (
            <HStack space={2} alignItems={'center'}>
              <FontAwesomeIcon name={'clock-o'} style={{ width: 12 }} />
              <Text>
                {data.schedule.startedAt &&
                  data.schedule.endedAt &&
                  `${format(new Date(data.schedule.startedAt), 'HH:mm')} - ${format(
                    new Date(data.schedule.endedAt),
                    'HH:mm'
                  )}`}
              </Text>
            </HStack>
          )}

          {loading ? (
            <Skeleton width={32} height={4} />
          ) : (
            <HStack space={2} alignItems={'center'}>
              <FontAwesomeIcon name={'calendar'} style={{ width: 12 }} />
              <Text>
                {data.schedule.startedAt &&
                  format(new Date(data.schedule.startedAt), 'EEEE, dd MMM yyyy')}
              </Text>
            </HStack>
          )}

          {loading ? (
            <Skeleton width={8} height={4} />
          ) : (
            <HStack space={2} alignItems={'center'}>
              <FontAwesomeIcon name={'user-o'} style={{ width: 12 }} />
              <Text>{data.schedule.gymClass.trainer.name}</Text>
            </HStack>
          )}

          <Divider />

          {loading ? (
            <Skeleton width={16} height={4} />
          ) : (
            <Text>{`Capacity: ${data.schedule.bookingCount} / ${data.schedule.gymClass.capacity}`}</Text>
          )}

          {loading ? (
            <Skeleton height={20} width={'100%'} borderRadius={8} />
          ) : (
            <ScrollView>
              <HStack space={4}>
                {bookingList.length > 0 && (
                  <Card
                    style={{
                      padding: 16,
                      borderRadius: 8,
                      flex: 1,
                    }}
                  >
                    <VStack>
                      <Text style={{ marginBottom: 8 }}>{'Booking List'}</Text>

                      {bookingList.map((booking, index) => (
                        <HStack key={index} space={1}>
                          <Text
                            style={{ minWidth: (String(waitingList.length).length + 1) * 8 }}
                          >{`${index + 1}.`}</Text>
                          <Text numberOfLines={1}>{booking.transaction.member.name}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Card>
                )}

                {waitingList.length > 0 && (
                  <Card
                    style={{
                      padding: 16,
                      borderRadius: 8,
                      flex: 1,
                    }}
                  >
                    <VStack>
                      <Text style={{ marginBottom: 8 }}>{'Waiting List'}</Text>

                      {waitingList.map((booking, index) => (
                        <HStack key={index} space={1}>
                          <Text
                            style={{ minWidth: (String(waitingList.length).length + 1) * 8 }}
                          >{`${index + 1}.`}</Text>
                          <Text numberOfLines={1}>{booking.transaction.member.name}</Text>
                        </HStack>
                      ))}
                    </VStack>
                  </Card>
                )}
              </HStack>
            </ScrollView>
          )}
        </VStack>
        {/* </ScrollView> */}

        <HStack mt={4} justifyContent={'space-between'} space={2} alignItems={'center'}>
          <Button
            flex={1}
            onPress={handleBook}
            isLoading={isBooking}
            isDisabled={disabledBook}
            _disabled={{ style: { cursor: 'default' } }}
          >
            {bookLabel}
          </Button>

          {booked && (!passed || booking?.waitingList) && (
            <Pressable justifyContent={'center'} alignItems={'center'} size={10} onPress={onOpen}>
              <FontAwesomeIcon name={'ellipsis-v'} size={16} />
            </Pressable>
          )}
        </HStack>
      </VStack>

      <Actionsheet isOpen={isOpen} onClose={onClose} style={mobileWebStyle}>
        <Actionsheet.Content>
          <Actionsheet.Item
            startIcon={<FontAwesomeIcon color={textColor} name={'trash'} size={16} />}
            onPress={handleCancelBook}
            isLoading={deleting}
          >
            {'Cancel'}
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </ScreenBackground>
  );
};

export default ClassScheduleScreen;
