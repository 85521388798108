import { camelCase, startCase } from 'lodash';
import Toast from 'react-native-root-toast';

const showErrorMessage = (errors: string[] | object | string) => {
  let errorMessage = '';
  if (Array.isArray(errors)) {
    errorMessage = errors.join(' ');
  } else if (errors && typeof errors === 'object') {
    const errorMessages: string[] = [];
    Object.entries(errors).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(val => errorMessages.push(`${startCase(camelCase(key))} ${val}.`));
      } else {
        errorMessages.push(`${startCase(camelCase(key))} ${value}.`);
      }
    });

    errorMessage = errorMessages.join(' ');
  } else {
    errorMessage = errors;
  }

  Toast.show(errorMessage, {
    backgroundColor: '#fca5a5',
    textColor: 'black',
    opacity: 1,
    position: -50,
  });
};

export default showErrorMessage;
