import { useLinkProps } from '@react-navigation/native';
import { Box, IBoxProps, IPressableProps, Pressable, Text } from 'native-base';
import React, { FC } from 'react';
import { Platform } from 'react-native';

type Props = Parameters<typeof useLinkProps>[0] & IBoxProps & IPressableProps;

const LinkNavigation: FC<Props> = ({ to, action, children, _text, ...props }) => {
  const { onPress, ...linkProps } = useLinkProps({ to, action });

  const [isHovered, setIsHovered] = React.useState(false);

  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <Box
        // @ts-ignore
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ opacity: isHovered ? 0.8 : 1 }}
        _text={{ textDecorationLine: 'underline', ...(_text ? _text : {}) }}
        {...linkProps}
        {...props}
      >
        {children}
      </Box>
    );
  }

  return (
    <Pressable onPress={onPress} {...linkProps} {...props}>
      <Text textDecorationLine={'underline'}>{children}</Text>
    </Pressable>
  );
};

export default LinkNavigation;
