import { useEffect, useState } from 'react';
import { AppState, ColorSchemeName, useColorScheme as _useColorScheme } from 'react-native';

const useColorScheme = (): NonNullable<ColorSchemeName> => {
  const colorScheme = _useColorScheme() as NonNullable<ColorSchemeName>;
  const [currentScheme, setCurrentScheme] = useState(colorScheme);

  useEffect(() => {
    const appStateListener = AppState.addEventListener('change', appState => {
      if (appState === 'active') {
        setCurrentScheme(colorScheme);
      }
    });

    return () => {
      appStateListener?.remove();
    };
  }, [colorScheme]);

  return currentScheme;
};

export default useColorScheme;
