import { Text, HStack, Switch } from 'native-base';
import React from 'react';

import useColorMode from '~/hooks/useColorMode';

export default function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack space={2} alignItems={'center'}>
      <Switch
        isChecked={colorMode === 'dark'}
        onToggle={toggleColorMode}
        aria-label={colorMode === 'light' ? 'switch to dark mode' : 'switch to light mode'}
      />
      <Text>{'Dark mode'}</Text>
    </HStack>
  );
}
