import { FontAwesome } from '@expo/vector-icons';
import { useColorModeValue, useToken } from 'native-base';
import React from 'react';

type Props = React.ComponentProps<typeof FontAwesome>;

const FontAwesomeIcon = (props: Props) => {
  const [lightText, darkText] = useToken('colors', ['lightText', 'darkText']);
  const textColor = useColorModeValue(darkText, lightText);

  return <FontAwesome color={textColor} {...props} />;
};

export default FontAwesomeIcon;
