import { extendTheme, themeTools } from 'native-base';

const lightTheme = {
  text: 'dark.100',
  primary: 'primary.500',
};

const darkTheme = {
  text: 'gray.300',
  primary: 'primary.300',
};

export const theme = extendTheme({
  config: {
    accessibleColors: true,
  },
  colors: {
    // Add new color
    primary: {
      50: '#ffe5e5',
      100: '#fabbbb',
      200: '#f08f8f',
      300: '#e86363',
      400: '#e13838',
      500: '#c71e1e',
      600: '#9c1617',
      700: '#700e10',
      800: '#450607',
      900: '#1e0000',
    },
    secondary: {
      50: '#fbf0f2',
      100: '#dcd8d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#282626',
      900: '#150a0d',
    },
    singletons: {
      lightText: darkTheme['text'],
      darkText: lightTheme['text'],
    },
  },
  components: {
    Heading: {
      baseStyle: (props: any) => {
        return {
          color: themeTools.mode(lightTheme['text'], darkTheme['text'])(props),
        };
      },
    },
    Text: {
      baseStyle: (props: any) => {
        return {
          color: themeTools.mode(lightTheme['text'], darkTheme['text'])(props),
        };
      },
    },
  },
});
