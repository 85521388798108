import { gql, useMutation, useQuery } from '@apollo/client';
import { useLinkTo } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { format } from 'date-fns';
import {
  VStack,
  Button,
  Text,
  Heading,
  Toast,
  Skeleton,
  HStack,
  Pressable,
  Actionsheet,
  useDisclose,
  useToken,
  useColorModeValue,
} from 'native-base';
import React, { FC } from 'react';

import { MainStackParamList } from '..';

import FontAwesomeIcon from '~/components/FontAwesomeIcon';
import ScreenBackground from '~/components/ScreenBackground';
import { useMobileWebStyle } from '~/navigation';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

const ClassScheduleScreen: FC<StackScreenProps<MainStackParamList, 'TrainerSchedule'>> = ({
  route,
}) => {
  const { isOpen, onOpen, onClose } = useDisclose();
  const mobileWebStyle = useMobileWebStyle();
  const { scheduleId, trainerId, trainerName, startedAt, endedAt } = route.params;
  const [lightText, darkText] = useToken('colors', ['lightText', 'darkText']);
  const textColor = useColorModeValue(darkText, lightText);
  const linkTo = useLinkTo();

  const {
    loading,
    data = {
      schedule: {
        id: scheduleId,
        startedAt,
        endedAt,
        trainer: { id: trainerId, name: trainerName },
      },
    },
  } = useQuery(
    gql`
      query GetSchedule($id: ID!) {
        schedule(id: $id) {
          id
          startedAt
          endedAt
          trainer {
            id
            name
          }
          bookingStatus
          booking {
            id
          }
        }
      }
    `,
    {
      variables: {
        id: scheduleId,
      },
      skip: Number(scheduleId) < 0,
    }
  );

  const [createTrainerBooking, { loading: booking }] = useMutation(
    gql`
      mutation CreateTrainerBookingMutation($input: CreateTrainerBookingInput!) {
        createTrainerBooking(input: $input) {
          booking {
            id
            schedule {
              id
            }
            transaction {
              id
            }
          }
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.createTrainerBooking.booking) {
          Toast.show({ title: 'Booking created successfuly', status: 'success' });
          linkTo({
            // @ts-ignore
            screen: 'TrainerSchedule',
            // @ts-ignore
            params: { scheduleId: result.createTrainerBooking.booking.schedule.id },
          });
        } else {
          const errors = result.createTrainerBooking.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
      refetchQueries: ['GetSchedule', 'GetBookings'],
    }
  );

  const handleBook = async () => {
    await createTrainerBooking({
      variables: {
        input: {
          trainerId,
          startedAt,
          endedAt,
        },
      },
    });
  };

  const [deleteTrainerBooking, { loading: deleting }] = useMutation(
    gql`
      mutation DeleteTrainerScheduleMutation($input: DeleteTrainerScheduleInput!) {
        deleteTrainerSchedule(input: $input) {
          success
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.deleteTrainerSchedule.success) {
          Toast.show({ title: 'Booking deleted successfuly', status: 'success' });
          linkTo({
            // @ts-ignore
            screen: 'TrainerSchedule',
            params: {
              // @ts-ignore
              scheduleId: -new Date(data.schedule.startedAt).getTime() / 1000,
              trainerId: data.schedule.trainer.id,
              trainerName: data.schedule.trainer.name,
              startedAt: data.schedule.startedAt,
              endedAt: data.schedule.endedAt,
            },
          });
        } else {
          const errors = result.deleteTrainerSchedule.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
      refetchQueries: ['GetSchedule', 'GetBookings'],
    }
  );

  const handleCancelBook = async () => {
    await deleteTrainerBooking({
      variables: {
        input: {
          id: data.schedule?.id,
        },
      },
    });
    onClose();
  };

  const passed =
    data.schedule.startedAt && new Date(data.schedule.startedAt).getTime() <= new Date().getTime();
  const booked = data.schedule.bookingStatus === 'BOOKED';
  const disabledBook = loading || booked || passed;
  const bookLabel = booked ? 'Booked' : passed ? 'Passed' : 'Book';

  return (
    <ScreenBackground safeAreaBottom>
      <VStack justifyContent={'space-between'} flex={1}>
        <VStack space={3}>
          {loading ? (
            <Skeleton width={64} height={6} />
          ) : (
            <Heading>{`PT Session with ${data.schedule.trainer.name}`}</Heading>
          )}

          <VStack>
            {loading ? (
              <Skeleton width={16} height={4} />
            ) : (
              <HStack space={2} alignItems={'center'}>
                <FontAwesomeIcon name={'clock-o'} />
                <Text>
                  {data.schedule.startedAt &&
                    data.schedule.endedAt &&
                    `${format(new Date(data.schedule.startedAt), 'HH:mm')} - ${format(
                      new Date(data.schedule.endedAt),
                      'HH:mm'
                    )}`}
                </Text>
              </HStack>
            )}

            {loading ? (
              <Skeleton width={32} height={4} />
            ) : (
              <HStack space={2} alignItems={'center'}>
                <FontAwesomeIcon name={'calendar'} />
                <Text>
                  {data.schedule.startedAt &&
                    format(new Date(data.schedule.startedAt), 'EEEE, dd MMM yyyy')}
                </Text>
              </HStack>
            )}
          </VStack>
        </VStack>

        <HStack justifyContent={'space-between'} space={2} alignItems={'center'}>
          <Button
            flex={1}
            onPress={handleBook}
            isLoading={booking}
            isDisabled={disabledBook}
            _disabled={{ style: { cursor: 'default' } }}
          >
            {bookLabel}
          </Button>

          {booked && !passed && (
            <Pressable justifyContent={'center'} alignItems={'center'} size={10} onPress={onOpen}>
              <FontAwesomeIcon name={'ellipsis-v'} size={16} />
            </Pressable>
          )}
        </HStack>
      </VStack>

      <Actionsheet isOpen={isOpen} onClose={onClose} style={mobileWebStyle}>
        <Actionsheet.Content>
          <Actionsheet.Item
            startIcon={<FontAwesomeIcon color={textColor} name={'trash'} size={16} />}
            onPress={handleCancelBook}
            isLoading={deleting}
          >
            {'Cancel'}
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </ScreenBackground>
  );
};

export default ClassScheduleScreen;
