import { gql, useMutation, useQuery } from '@apollo/client';
import * as ImagePicker from 'expo-image-picker';
import {
  VStack,
  Text,
  Avatar,
  Skeleton,
  useDisclose,
  Actionsheet,
  Pressable,
  Image,
  Box,
  Spinner,
  Toast,
} from 'native-base';
import React, { useEffect, useState } from 'react';

import FontAwesomeIcon from '~/components/FontAwesomeIcon';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';
import { prependApiUrl } from '~/utils/urlUtil';

export default function ProfilePhotoContainer() {
  const [cameraStatus, requestCameraPermission] = ImagePicker.useCameraPermissions();
  const [mediaStatus, requestMediaPermission] = ImagePicker.useMediaLibraryPermissions();
  const [photoUrl, setPhotoUrl] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclose();

  const { loading, data = { currentMemberAccount: { name: '', photoUrl: '' } } } = useQuery(
    gql`
      query GetCurrentMemberAccount {
        currentMemberAccount {
          name
          photoUrl(size: 400)
        }
      }
    `
  );

  const [updateCurrentMemberAccount, { loading: updating }] = useMutation(
    gql`
      mutation UpdateCurrentMemberAccount($input: UpdateCurrentMemberAccountInput!) {
        updateCurrentMemberAccount(input: $input) {
          currentMemberAccount {
            photoUrl
            name
          }
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.updateCurrentMemberAccount.currentMemberAccount) {
          setPhotoUrl('');
          Toast.show({ title: 'Photo updated successfuly', status: 'success' });
          onClose();
        } else {
          const errors = result.updateCurrentMemberAccount.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
      refetchQueries: ['GetCurrentMemberAccount'],
    }
  );

  const handleImagePicked = async (pickerResult: ImagePicker.ImagePickerResult) => {
    try {
      if (pickerResult.cancelled) {
        return;
      } else {
        updateCurrentMemberAccount({
          variables: {
            input: {
              photoBase64: pickerResult.base64,
            },
          },
        });
      }
    } catch (e: any) {
      Sentry.captureException(e);
      showErrorMessage(e);
    }
  };

  const takePhoto = async () => {
    if (!cameraStatus?.granted && !(await requestCameraPermission()).granted) {
      Toast.show({ title: 'Please allow camera access', status: 'error' });
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
    });

    handleImagePicked(result);
  };

  const pickImage = async () => {
    if (!mediaStatus?.granted && !(await requestMediaPermission()).granted) {
      Toast.show({ title: 'Please allow media access', status: 'error' });
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
    });

    handleImagePicked(result);
  };

  useEffect(() => {
    setPhotoUrl(prependApiUrl(data.currentMemberAccount.photoUrl));
  }, [data.currentMemberAccount.photoUrl]);

  return (
    <>
      <VStack space={2} alignItems={'center'} mb={8} mt={4}>
        <Pressable onPress={onOpen}>
          <Avatar
            source={
              photoUrl
                ? {
                    uri: photoUrl,
                  }
                : undefined
            }
          >
            {(data.currentMemberAccount.name as string)
              .split(' ')
              .map(n => n[0])
              .filter((_, i) => i < 2)
              .join('')
              .toUpperCase()}
            <Avatar.Badge bg={'rgba(0, 0, 0, 0)'}>
              <FontAwesomeIcon name={'camera'} />
            </Avatar.Badge>
          </Avatar>
        </Pressable>
        {loading ? (
          <Skeleton width={32} height={5} />
        ) : (
          <Text>{data.currentMemberAccount.name}</Text>
        )}
      </VStack>

      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Box p={4} justifyContent={'center'} mb={4}>
            {updating ? (
              <Spinner />
            ) : (
              <Image
                source={
                  photoUrl
                    ? {
                        uri: photoUrl,
                      }
                    : undefined
                }
                size={'2xl'}
                alt={'Profile photo'}
              />
            )}
          </Box>

          <Actionsheet.Item isDisabled={updating} onPress={takePhoto}>
            {'Take photo'}
          </Actionsheet.Item>
          <Actionsheet.Item isDisabled={updating} onPress={pickImage}>
            {'Choose existing photo'}
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
}
