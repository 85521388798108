import { gql, useMutation } from '@apollo/client';
import * as Device from 'expo-device';
import { Subscription } from 'expo-modules-core';
import * as Notifications from 'expo-notifications';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Platform } from 'react-native';

import * as Sentry from '~/utils/sentry';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}

export default function useExpoNotifications() {
  const [expoPushToken, setExpoPushToken] = useState<string>();
  const [notification, setNotification] = useState<Notifications.Notification>();
  const notificationListener = useRef<Subscription>();

  const [updateExpoPushToken] = useMutation(
    gql`
      mutation UpdateExpoPushToken($input: UpdateExpoPushTokenInput!) {
        updateExpoPushToken(input: $input) {
          success
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.updateExpoPushToken.errors) {
          Sentry.captureAnyMessage(result.updateExpoPushToken.errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
      },
    }
  );

  const handleUpdateExpoPushToken = useCallback(
    async (expoPushToken?: string) => {
      await updateExpoPushToken({
        variables: {
          input: {
            expoPushToken,
          },
        },
      });
    },
    [updateExpoPushToken]
  );

  useEffect(() => {
    if (Platform.OS === 'web') return;

    registerForPushNotificationsAsync().then(token => {
      setExpoPushToken(token);

      handleUpdateExpoPushToken(token);
    });

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(notificationListener.current);
      }
    };
  }, [handleUpdateExpoPushToken]);

  return useMemo(
    () => ({
      expoPushToken,
      notification,
    }),
    [expoPushToken, notification]
  );
}
