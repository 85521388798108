/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useLinkTo,
  useFocusEffect,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import { Box, Icon, Pressable, useColorModeValue, useToken } from 'native-base';
import * as React from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { AuthStackParamList, HomeTabParamList, MainStackParamList, SelectGymParamList } from '..';
import LinkingConfiguration from './LinkingConfiguration';

import useColorMode from '~/hooks/useColorMode';
import useExpoNotifications from '~/hooks/useExpoNotifications';
import { useAuth } from '~/providers/AuthProvider';
import AboutGymScreen from '~/screens/AboutGymScreen';
import AccountScreen from '~/screens/AccountScreen';
import ActivityScreen from '~/screens/ActivityScreen';
import CheckInScreen from '~/screens/CheckInScreen';
import ClassScheduleScreen from '~/screens/ClassScheduleScreen';
import MembershipScreen from '~/screens/MembershipScreen';
import MidtransSnapScreen from '~/screens/MidtransSnapScreen';
import NotFoundScreen from '~/screens/NotFoundScreen';
import NotificationsScreen from '~/screens/NotificationsScreen';
import ProfileScreen from '~/screens/ProfileScreen';
import SelectGymScreen from '~/screens/SelectGymScreen';
import SettingsScreen from '~/screens/SettingsScreen';
import TimetableScreen from '~/screens/TimetableScreen';
import TrainerScheduleScreen from '~/screens/TrainerScheduleScreen';
import TrainerSchedulesScreen from '~/screens/TrainerSchedulesScreen';
import TransactionsScreen from '~/screens/TransactionsScreen';
import ResetPasswordScreen from '~/screens/auth/ResetPasswordScreen';
import SignInScreen from '~/screens/auth/SignInScreen';
import SignUpScreen from '~/screens/auth/SignUpScreen';
import UpdatePasswordScreen from '~/screens/auth/UpdatePasswordScreen';
import { RootStackParamList } from '~/types';

export default function Navigation() {
  const { colorMode } = useColorMode();

  return (
    <>
      <NavigationContainer
        linking={LinkingConfiguration}
        theme={colorMode === 'dark' ? DarkTheme : DefaultTheme}
      >
        <RootNavigator />
      </NavigationContainer>
      <StatusBar style={colorMode === 'dark' ? 'light' : 'dark'} />
    </>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createStackNavigator<RootStackParamList>();

export const useMobileWebStyle = () => {
  const mobileWebStyle = React.useMemo(
    () => (Platform.OS === 'web' ? { maxWidth: 768, marginHorizontal: 'auto', width: '100%' } : {}),
    []
  );

  return mobileWebStyle;
};

function RootNavigator() {
  const { token } = useAuth();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={'Root'}
        component={token ? SelectGymStackNavigator : AuthStackNavigator}
      />
      <Stack.Screen name={'NotFound'} component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Stack.Navigator>
  );
}

const AuthStack = createStackNavigator<AuthStackParamList>();
const AuthStackNavigator = () => {
  const mobileWebStyle = useMobileWebStyle();

  return (
    <AuthStack.Navigator
      initialRouteName={'SignIn'}
      screenOptions={{
        headerShown: false,
        cardStyle: mobileWebStyle,
      }}
    >
      <AuthStack.Screen
        name={'SignIn'}
        component={SignInScreen}
        options={{
          title: 'Sign in',
        }}
      />
      <AuthStack.Screen
        name={'SignUp'}
        component={SignUpScreen}
        options={{
          title: 'Sign up',
        }}
      />
      <AuthStack.Screen
        name={'ResetPassword'}
        component={ResetPasswordScreen}
        options={{
          title: 'Reset password',
        }}
      />
      <AuthStack.Screen
        name={'UpdatePassword'}
        component={UpdatePasswordScreen}
        options={{
          title: 'Update password',
        }}
      />
    </AuthStack.Navigator>
  );
};

const SelectGymStack = createStackNavigator<SelectGymParamList>();
const SelectGymStackNavigator = () => {
  const mobileWebStyle = useMobileWebStyle();

  return (
    <SelectGymStack.Navigator
      initialRouteName={'SelectGym'}
      screenOptions={{
        headerShown: false,
        cardStyle: mobileWebStyle,
      }}
    >
      <SelectGymStack.Screen name={'Main'} component={MainStackNavigator} />
      <SelectGymStack.Screen
        name={'SelectGym'}
        component={SelectGymScreen}
        options={{
          title: 'Select gym',
        }}
      />
    </SelectGymStack.Navigator>
  );
};

const MainStack = createStackNavigator<MainStackParamList>();
const MainStackNavigator = () => {
  const mobileWebStyle = useMobileWebStyle();
  const [lightText, darkText] = useToken('colors', ['lightText', 'darkText']);
  const textColor = useColorModeValue(darkText, lightText);

  const linkTo = useLinkTo();

  useExpoNotifications();

  useFocusEffect(() => {
    (async () => {
      if (!(await AsyncStorage.getItem('@gym_id'))) {
        linkTo('/select-gym');
      }
    })();
  });

  return (
    <MainStack.Navigator
      initialRouteName={'Home'}
      screenOptions={{
        cardStyle: mobileWebStyle,
        headerBackTitleVisible: false,
        headerTintColor: textColor,
        gestureEnabled: true,
      }}
    >
      <MainStack.Screen
        name={'Home'}
        component={HomeBottomTabNavigator}
        options={{ headerShown: false }}
      />
      <MainStack.Screen
        name={'CheckIn'}
        component={CheckInScreen}
        options={{
          title: 'Check In',
          headerStyle: {
            backgroundColor: 'rgb(255, 255, 255)',
            borderBottomColor: 'rgb(216, 216, 216)',
          },
          headerTintColor: 'rgb(28, 28, 30)',
        }}
      />

      <MainStack.Screen name={'Notifications'} component={NotificationsScreen} />

      <MainStack.Screen
        name={'TrainerSchedules'}
        component={TrainerSchedulesScreen}
        options={{ title: 'Trainer schedules' }}
      />
      <MainStack.Screen
        name={'ClassSchedule'}
        component={ClassScheduleScreen}
        options={{ title: 'Class detail' }}
      />
      <MainStack.Screen
        name={'TrainerSchedule'}
        component={TrainerScheduleScreen}
        options={{ title: 'Trainer detail' }}
      />
      <MainStack.Screen
        name={'MidtransSnap'}
        component={MidtransSnapScreen}
        options={{ title: 'Payment' }}
      />
      <MainStack.Screen name={'Account'} component={AccountScreen} />
      <MainStack.Screen name={'Transactions'} component={TransactionsScreen} />
      <MainStack.Screen
        name={'AboutGym'}
        component={AboutGymScreen}
        options={{ title: 'About gym' }}
      />
      <MainStack.Screen name={'Settings'} component={SettingsScreen} />
    </MainStack.Navigator>
  );
};

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const HomeBottomTab = createBottomTabNavigator<HomeTabParamList>();

const HomeBottomTabNavigator = () => {
  const mobileWebStyle = useMobileWebStyle();
  const [primary500, primary300] = useToken('colors', ['primary.500', 'primary.300']);
  const primaryColor = useColorModeValue(primary500, primary300);
  const insets = useSafeAreaInsets();
  const linkTo = useLinkTo();

  return (
    <HomeBottomTab.Navigator
      initialRouteName={'Timetable'}
      screenOptions={{
        tabBarActiveTintColor: primaryColor,
        tabBarStyle: { paddingBottom: 6 + insets?.bottom, ...mobileWebStyle },
      }}
      sceneContainerStyle={mobileWebStyle}
    >
      <HomeBottomTab.Screen
        name={'Timetable'}
        component={TimetableScreen}
        options={{
          title: 'Timetable',
          tabBarIcon: ({ color }) => <TabBarIcon name={'list'} color={color} />,
          headerLeft: () => (
            <Box pl={4}>
              <Pressable onPress={() => linkTo('/check-in')}>
                <Icon as={FontAwesome} name={'qrcode'} />
              </Pressable>
            </Box>
          ),
          headerRight: () => (
            <Box pr={4}>
              <Pressable onPress={() => linkTo('/notifications')}>
                <Icon as={FontAwesome} name={'bell-o'} />
              </Pressable>
            </Box>
          ),
        }}
      />

      <HomeBottomTab.Screen
        name={'Membership'}
        component={MembershipScreen}
        options={{
          title: 'Membership',
          tabBarIcon: ({ color }) => <TabBarIcon name={'id-card'} color={color} />,
        }}
      />

      <HomeBottomTab.Screen
        name={'Activity'}
        component={ActivityScreen}
        options={{
          title: 'Activity',
          tabBarIcon: ({ color }) => <TabBarIcon name={'list-alt'} color={color} />,
        }}
      />

      <HomeBottomTab.Screen
        name={'Profile'}
        component={ProfileScreen}
        options={{
          title: 'Profile',
          tabBarIcon: ({ color }) => <TabBarIcon name={'user'} color={color} />,
        }}
      />
    </HomeBottomTab.Navigator>
  );
};

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={16} {...props} />;
}
