import { gql, useQuery } from '@apollo/client';
import { FontAwesome } from '@expo/vector-icons';
import { useLinkTo } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { endOfDay, format, isBefore, startOfDay } from 'date-fns';
import {
  Divider,
  Heading,
  Box,
  Pressable,
  Text,
  useColorModeValue,
  useToken,
  VStack,
  FlatList,
  View,
  Skeleton,
  HStack,
} from 'native-base';
import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import { Platform } from 'react-native';
import CalendarStrip from 'react-native-calendar-strip';

import { MainStackParamList } from '..';

import Card from '~/components/Card';
import ScreenBackground from '~/components/ScreenBackground';

const TrainerSchedulesScreen: FC<StackScreenProps<MainStackParamList, 'TrainerSchedules'>> = ({
  route,
}) => {
  const [selectedDate, setSelectedDate] = useState(startOfDay(new Date()).toISOString());
  const linkTo = useLinkTo();
  const bgColor = useColorModeValue('gray.50', 'dark.100');

  const { trainerId, trainerName } = route.params;

  const { loading, data, fetchMore, networkStatus } = useQuery(
    gql`
      query GetTrainerSchedules(
        $trainerId: ID!
        $startedFrom: ISO8601DateTime!
        $startedTo: ISO8601DateTime!
        $cursor: String
      ) {
        trainerSchedules(
          trainerId: $trainerId
          startedFrom: $startedFrom
          startedTo: $startedTo
          after: $cursor
        ) {
          edges {
            node {
              id
              startedAt
              endedAt
              schedulableType
              gymClass {
                id
                name
              }
              trainer {
                id
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      variables: {
        trainerId,
        startedFrom: startOfDay(new Date(selectedDate)).toISOString(),
        startedTo: endOfDay(new Date(selectedDate)).toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  // networkStatus === 2 is a setVariables event
  const flatListData = networkStatus === 2 ? [] : data?.trainerSchedules?.edges;

  const handlePress = ({ scheduleId, startedAt, endedAt }: any) => {
    linkTo({
      // @ts-ignore
      screen: 'TrainerSchedule',
      // @ts-ignore
      params: { scheduleId, trainerName, trainerId, startedAt, endedAt },
    });
  };

  return (
    <ScreenBackground p={4}>
      <Heading mb={4}>{trainerName}</Heading>

      <Divider />

      <>
        <ListHeaderComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

        <FlatList
          mt={4}
          data={flatListData}
          ItemSeparatorComponent={() => <View style={{ paddingTop: 16 }} />}
          showsVerticalScrollIndicator={false}
          keyExtractor={({ node: { id } }) => id}
          renderItem={({ item: { node } }) => {
            return <Item node={node} handlePress={handlePress} />;
          }}
          ListEmptyComponent={() =>
            loading ? null : (
              <VStack space={4} justifyContent={'center'} alignItems={'center'}>
                <Text>{'No schedules'}</Text>
              </VStack>
            )
          }
          onEndReached={() => {
            if (data?.trainers?.pageInfo?.hasNextPage) {
              fetchMore({
                variables: {
                  cursor: data?.trainers?.pageInfo?.endCursor,
                },
              });
            }
          }}
          ListFooterComponent={() => {
            return loading ? (
              <Skeleton
                mt={flatListData?.length > 0 ? 4 : 0}
                height={20}
                // @ts-ignore
                variant={'rect'}
                bgColor={bgColor}
                borderRadius={8}
              />
            ) : null;
          }}
        />
      </>
    </ScreenBackground>
  );
};

const ListHeaderComponent: FC<{
  selectedDate: string;
  setSelectedDate: Dispatch<SetStateAction<string>>;
}> = ({ selectedDate, setSelectedDate }) => {
  const [gray50, dark100, primary500, primary300, lightText, darkText, muted500] = useToken(
    'colors',
    ['gray.50', 'dark.100', 'primary.500', 'primary.300', 'lightText', 'darkText', 'muted.500']
  );
  const bgColor = useColorModeValue(gray50, dark100);
  const textColor = useColorModeValue(darkText, lightText);
  const primaryColor = useColorModeValue(primary500, primary300);
  const mutedColor = muted500;

  return (
    <Box mt={4} shadow={1}>
      <CalendarStrip
        scrollToOnSetSelectedDate={false}
        style={{
          paddingTop: 16,
          paddingBottom: 16,
          borderRadius: 8,
          height: Platform.OS === 'web' ? 140 : 100,
        }}
        calendarColor={bgColor}
        calendarHeaderStyle={{ color: textColor }}
        dateNumberStyle={{ color: textColor }}
        dateNameStyle={{ color: textColor }}
        highlightDateNameStyle={{ color: primaryColor }}
        highlightDateNumberStyle={{ color: primaryColor }}
        disabledDateNameStyle={{ color: mutedColor }}
        disabledDateNumberStyle={{ color: mutedColor }}
        leftSelector={
          <FontAwesome
            style={{
              paddingLeft: 16,
              paddingRight: 10,
            }}
            size={16}
            color={primaryColor}
            name={'chevron-left'}
          />
        }
        rightSelector={
          <FontAwesome
            style={{
              paddingRight: 16,
              paddingLeft: 10,
            }}
            size={16}
            color={primaryColor}
            name={'chevron-right'}
          />
        }
        onDateSelected={date => {
          requestAnimationFrame(() => {
            setSelectedDate(startOfDay(date.toDate()).toISOString());
          });
        }}
        selectedDate={startOfDay(new Date(selectedDate))}
        {...(Platform.OS === 'web' && { minDate: startOfDay(new Date()) })}
        datesBlacklist={date => {
          const dateObj = date.toDate();
          return isBefore(dateObj, startOfDay(new Date()));
        }}
      />
    </Box>
  );
};

const Item: FC<{ node: any; handlePress: any }> = ({ node, handlePress }) => {
  const disabled = Number(node.id) > 0;

  return (
    <Pressable
      // @ts-ignore
      onPress={() =>
        handlePress({ scheduleId: node.id, startedAt: node.startedAt, endedAt: node.endedAt })
      }
      disabled={disabled}
    >
      <Card
        style={{
          padding: 16,
          borderRadius: 8,
        }}
      >
        <HStack justifyContent={'space-between'}>
          <Text {...(disabled && { color: 'muted.500' })}>{`${format(
            new Date(node.startedAt),
            'HH:mm'
          )} - ${format(new Date(node.endedAt), 'HH:mm')}`}</Text>

          {disabled && <Text color={'muted.500'}>{'Unavailable'}</Text>}
        </HStack>
      </Card>
    </Pressable>
  );
};

export default TrainerSchedulesScreen;
