import { ColorMode, IColorModeContextProps, useColorMode as _useColorMode } from 'native-base';

// The useColorMode value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.

type ReturnType = IColorModeContextProps & {
  colorMode: NonNullable<ColorMode>;
};

export default function useColorMode(): ReturnType {
  return _useColorMode() as unknown as ReturnType;
}
