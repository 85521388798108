import { gql, useMutation, useQuery } from '@apollo/client';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { keyBy } from 'lodash';
import {
  Box,
  Button,
  Divider,
  FlatList,
  Heading,
  HStack,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclose,
  VStack,
} from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';

import Card from '~/components/Card';
import ScreenBackground from '~/components/ScreenBackground';
import TransactionItem from '~/components/TransactionItem';
import TransactionItemDetailSheet from '~/components/TransactionItemDetailSheet';
import { formatCurrency } from '~/utils/numberUtil';
import * as Sentry from '~/utils/sentry';
import showErrorMessage from '~/utils/showErrorMessage';

export default function MembershipScreen() {
  const [membershipId, setMembershipId] = useState('');
  const [membershipPackageId, setMembershipPackageId] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const { isOpen, onOpen, onClose } = useDisclose();

  const linkTo = useLinkTo();
  const bgColor = useColorModeValue('gray.50', 'dark.100');

  const inactiveProps = {
    variant: 'outline',
    _hover: { bg: 'transparent' },
    _pressed: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    _focusVisible: { bg: 'transparent' },
  };

  const {
    loading: loadingTransactions,
    data: transactionsData,
    refetch: refetchTransactions,
  } = useQuery(
    gql`
      query GetTransactions($cursor: String) {
        transactions(after: $cursor, status: "ACTIVE") {
          edges {
            node {
              id
              paidAt
              category
              membershipPackageName
              membershipName
              periodDay
              unlimitedSession
              trainingSession
              bookingUsed
              trainer {
                id
                name
              }
              gymClass {
                id
                name
                trainer {
                  id
                  name
                }
                capacity
              }
              payment {
                id
              }
              price
              startedAt
              endedAt
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const {
    data,
    loading,
    refetch: refetchMemberships,
  } = useQuery(
    gql`
      query GetMemberships {
        memberships {
          edges {
            node {
              id
              name
              category
              trainer {
                id
                name
              }
              gymClass {
                id
                name
              }
              channel
              membershipPackages {
                edges {
                  node {
                    id
                    name
                    unlimitedSession
                    trainingSession
                    periodDay
                    price
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const memberships = useMemo(() => {
    return data?.memberships?.edges || [];
  }, [data?.memberships?.edges]);

  const membershipById = useMemo(() => {
    return keyBy(memberships, ({ node }) => node.id);
  }, [memberships]);

  const membershipPackages = useMemo(() => {
    return membershipById[membershipId]?.node?.membershipPackages?.edges || [];
  }, [membershipById, membershipId]);

  const [payMembershipPackage, { loading: paying }] = useMutation(
    gql`
      mutation PayMembershipPackageMutation($input: PayMembershipPackageInput!) {
        payMembershipPackage(input: $input) {
          success
          clientKey
          token
          redirectUrl
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        const { redirectUrl, success, token, clientKey } = result.payMembershipPackage;

        if (success) {
          linkTo({
            // @ts-ignore
            screen: 'MidtransSnap',
            // @ts-ignore
            params: { redirectUrl, token, clientKey },
          });
        } else {
          const errors = result.payMembershipPackage.errors;
          Sentry.captureAnyMessage(errors);
          showErrorMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
        showErrorMessage(error);
      },
    }
  );

  const handlePay = async (id: string) => {
    const finishUrl = Linking.createURL('membership');

    await payMembershipPackage({
      variables: {
        input: {
          id,
          finishUrl,
        },
      },
    });
  };

  useEffect(() => {
    if (memberships.length > 0) {
      setMembershipId(memberships[0].node.id);
    }
  }, [memberships]);

  useFocusEffect(
    useCallback(() => {
      refetchTransactions();
      refetchMemberships();
    }, [refetchMemberships, refetchTransactions])
  );

  return (
    <ScreenBackground p={4}>
      <Heading mb={4} size={'md'}>
        {'Active memberships'}
      </Heading>

      <HStack mb={4}>
        {loadingTransactions ? (
          <VStack flex={1} space={4}>
            <Skeleton
              height={24}
              width={'100%'}
              // @ts-ignore
              variant={'rect'}
              bgColor={bgColor}
              borderRadius={8}
            />
          </VStack>
        ) : (
          <FlatList
            data={transactionsData?.transactions?.edges}
            horizontal
            ItemSeparatorComponent={() => <Box pl={4} />}
            keyExtractor={({ node: { id } }) => id}
            renderItem={({ item: { node } }) => (
              <TransactionItem
                item={node}
                onPress={node => {
                  setSelectedTransaction(node);
                  onOpen();
                }}
              />
            )}
            ListEmptyComponent={() =>
              loadingTransactions ? null : (
                <HStack
                  height={24}
                  width={Dimensions.get('window').width - 32}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Text>{'No active memberships'}</Text>
                </HStack>
              )
            }
          />
        )}
      </HStack>

      <Heading mb={4} size={'md'}>
        {'Buy memberships'}
      </Heading>

      <HStack mb={4}>
        {loading ? (
          <Skeleton
            height={10}
            width={'100%'}
            // @ts-ignore
            variant={'rect'}
            bgColor={bgColor}
          />
        ) : (
          <FlatList
            data={memberships}
            horizontal
            ItemSeparatorComponent={() => <Box pl={4} />}
            showsVerticalScrollIndicator={false}
            keyExtractor={({ node: { id } }) => id}
            renderItem={({ item: { node } }) => (
              <Button
                borderRadius={16}
                onPress={() => setMembershipId(node.id)}
                {...(membershipId !== node.id && inactiveProps)}
              >
                {node.name}
              </Button>
            )}
            ListEmptyComponent={() =>
              loading ? null : (
                <VStack space={4} justifyContent={'center'} alignItems={'center'}>
                  <Text>{'No memberships'}</Text>
                </VStack>
              )
            }
          />
        )}
      </HStack>

      <Divider mb={4} />

      {loading ? (
        <Skeleton
          height={10}
          width={'100%'}
          // @ts-ignore
          variant={'rect'}
          bgColor={bgColor}
        />
      ) : (
        <FlatList
          data={membershipPackages}
          ItemSeparatorComponent={() => <Box pt={4} />}
          showsVerticalScrollIndicator={false}
          keyExtractor={({ node: { id } }) => id}
          renderItem={({ item: { node } }) => (
            <Card padding={4} borderRadius={8}>
              <HStack space={4} alignItems={'center'}>
                <Box flex={1}>
                  <Text>{node.name}</Text>
                  <Text>{`${node.periodDay} Days`}</Text>
                </Box>
                <Box flex={1}>
                  <Text>{formatCurrency(node.price)}</Text>
                </Box>
                <Box flex={1}>
                  <Button
                    borderRadius={16}
                    onPress={() => {
                      setMembershipPackageId(node.id);
                      handlePay(node.id);
                    }}
                    isLoading={paying && membershipPackageId === node.id}
                  >
                    {'Buy'}
                  </Button>
                </Box>
              </HStack>
            </Card>
          )}
          ListFooterComponent={() => {
            return loading ? (
              <Skeleton
                mt={membershipPackages?.length > 0 ? 4 : 0}
                height={10}
                // @ts-ignore
                variant={'rect'}
                bgColor={bgColor}
              />
            ) : null;
          }}
          ListEmptyComponent={() =>
            loading ? null : (
              <VStack space={4} justifyContent={'center'} alignItems={'center'}>
                <Text>{'No packages'}</Text>
              </VStack>
            )
          }
        />
      )}

      <TransactionItemDetailSheet item={selectedTransaction} isOpen={isOpen} onClose={onClose} />
    </ScreenBackground>
  );
}
