import { gql, useQuery } from '@apollo/client';
import { FontAwesome } from '@expo/vector-icons';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Pressable,
  VStack,
  useColorModeValue,
  useToken,
} from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { AppState } from 'react-native';

import ScreenBackground from '~/components/ScreenBackground';
import ClassFlatListContainer from '~/containers/ClassFlatListContainer';
import TrainerFlatListContainer from '~/containers/TrainerFlatListContainer';

export default function TimetableScreen({ navigation }: any) {
  const [tabIndex, setTabIndex] = useState(0);

  const linkTo = useLinkTo();
  const [primary500, primary300] = useToken('colors', ['primary.500', 'primary.300']);
  const primaryColor = useColorModeValue(primary500, primary300);

  const inactiveTabProps = {
    variant: 'outline',
    _hover: { bg: 'transparent' },
    _pressed: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    _focusVisible: { bg: 'transparent' },
  };

  const { refetch: refetchUnreadNotificationsCount } = useQuery(
    gql`
      query GetUnreadNotificationsCount {
        unreadNotificationsCount
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (data?.unreadNotificationsCount > 0) {
          navigation.setOptions({
            headerRight: () => (
              <Box pr={4}>
                <Pressable onPress={() => linkTo('/notifications')}>
                  <VStack>
                    <Badge
                      _text={{ color: 'lightText' }}
                      bg={primaryColor}
                      rounded={'full'}
                      mb={-6}
                      mr={-3}
                      zIndex={1}
                      variant={'solid'}
                      alignSelf={'flex-end'}
                    >
                      {data?.unreadNotificationsCount}
                    </Badge>
                    <Icon as={FontAwesome} name={'bell-o'} />
                  </VStack>
                </Pressable>
              </Box>
            ),
          });
        } else {
          navigation.setOptions({
            headerRight: () => (
              <Box pr={4}>
                <Pressable onPress={() => linkTo('/notifications')}>
                  <Icon as={FontAwesome} name={'bell-o'} />
                </Pressable>
              </Box>
            ),
          });
        }

        Notifications.setBadgeCountAsync(data?.unreadNotificationsCount || 0);
      },
    }
  );

  useFocusEffect(
    useCallback(() => {
      refetchUnreadNotificationsCount();
    }, [refetchUnreadNotificationsCount])
  );

  useEffect(() => {
    const appStateListener = AppState.addEventListener('change', appState => {
      if (appState === 'active') {
        refetchUnreadNotificationsCount();
      }
    });

    return () => {
      appStateListener?.remove();
    };
  }, [refetchUnreadNotificationsCount]);

  return (
    <ScreenBackground p={4}>
      <HStack space={4} mb={4}>
        <Button
          borderRadius={16}
          {...(tabIndex !== 0 && inactiveTabProps)}
          onPress={() => setTabIndex(0)}
        >
          {'Classes'}
        </Button>
        <Button
          borderRadius={16}
          {...(tabIndex !== 1 && inactiveTabProps)}
          onPress={() => setTabIndex(1)}
        >
          {'Trainers'}
        </Button>
      </HStack>

      <Divider />

      {tabIndex === 0 && <ClassFlatListContainer />}
      {tabIndex === 1 && <TrainerFlatListContainer />}
    </ScreenBackground>
  );
}
