import { gql, useQuery } from '@apollo/client';
import { FontAwesome } from '@expo/vector-icons';
import { useLinkTo } from '@react-navigation/native';
import {
  endOfDay,
  format,
  formatDuration,
  intervalToDuration,
  isBefore,
  startOfDay,
} from 'date-fns';
import {
  Box,
  FlatList,
  Heading,
  HStack,
  Pressable,
  Skeleton,
  Text,
  useColorModeValue,
  useToken,
  VStack,
} from 'native-base';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Platform } from 'react-native';
import CalendarStrip from 'react-native-calendar-strip';

import Card from '~/components/Card';
import FontAwesomeIcon from '~/components/FontAwesomeIcon';

export default function ClassFlatListContainer() {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const linkTo = useLinkTo();
  const bgColor = useColorModeValue('gray.50', 'dark.100');

  const { loading, data, fetchMore, networkStatus } = useQuery(
    gql`
      query GetSchedules(
        $startedFrom: ISO8601DateTime!
        $startedTo: ISO8601DateTime!
        $cursor: String
      ) {
        schedules(startedFrom: $startedFrom, startedTo: $startedTo, after: $cursor) {
          edges {
            node {
              id
              startedAt
              endedAt
              gymClass {
                id
                name
                trainer {
                  id
                  name
                }
                capacity
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      variables: {
        startedFrom: startOfDay(new Date(selectedDate)).toISOString(),
        startedTo: endOfDay(new Date(selectedDate)).toISOString(),
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  // networkStatus === 2 is a setVariables event
  const flatListData = networkStatus === 2 ? [] : data?.schedules?.edges;

  return (
    <>
      <ListHeaderComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

      <FlatList
        mt={4}
        data={flatListData}
        ItemSeparatorComponent={() => <Box pt={4} />}
        ListEmptyComponent={() =>
          loading ? null : (
            <VStack space={4} justifyContent={'center'} alignItems={'center'}>
              <Text>{'No schedules'}</Text>
            </VStack>
          )
        }
        keyExtractor={({ node: { id } }) => id}
        renderItem={({ item: { node } }) => <Item node={node} linkTo={linkTo} />}
        onEndReached={() => {
          if (data?.schedules?.pageInfo?.hasNextPage) {
            fetchMore({
              variables: {
                cursor: data?.schedules?.pageInfo?.endCursor,
              },
            });
          }
        }}
        ListFooterComponent={() => {
          return loading ? (
            <Skeleton
              mt={flatListData?.length > 0 ? 4 : 0}
              height={20}
              // @ts-ignore
              variant={'rect'}
              bgColor={bgColor}
              borderRadius={8}
            />
          ) : null;
        }}
      />
    </>
  );
}

const ListHeaderComponent: FC<{
  selectedDate: string;
  setSelectedDate: Dispatch<SetStateAction<string>>;
}> = ({ selectedDate, setSelectedDate }) => {
  const [gray50, dark100, primary500, primary300, lightText, darkText, muted500] = useToken(
    'colors',
    ['gray.50', 'dark.100', 'primary.500', 'primary.300', 'lightText', 'darkText', 'muted.500']
  );
  const bgColor = useColorModeValue(gray50, dark100);
  const textColor = useColorModeValue(darkText, lightText);
  const primaryColor = useColorModeValue(primary500, primary300);
  const mutedColor = muted500;

  return (
    <Box mt={4} shadow={1}>
      <CalendarStrip
        scrollToOnSetSelectedDate={false}
        style={{
          paddingTop: 16,
          paddingBottom: 16,
          borderRadius: 8,
          height: Platform.OS === 'web' ? 140 : 100,
        }}
        calendarColor={bgColor}
        calendarHeaderStyle={{ color: textColor }}
        dateNumberStyle={{ color: textColor }}
        dateNameStyle={{ color: textColor }}
        highlightDateNameStyle={{ color: primaryColor }}
        highlightDateNumberStyle={{ color: primaryColor }}
        disabledDateNameStyle={{ color: mutedColor }}
        disabledDateNumberStyle={{ color: mutedColor }}
        leftSelector={
          <FontAwesome
            style={{
              paddingLeft: 16,
              paddingRight: 10,
            }}
            size={16}
            color={primaryColor}
            name={'chevron-left'}
          />
        }
        rightSelector={
          <FontAwesome
            style={{
              paddingRight: 16,
              paddingLeft: 10,
            }}
            size={16}
            color={primaryColor}
            name={'chevron-right'}
          />
        }
        onDateSelected={date => {
          requestAnimationFrame(() => {
            setSelectedDate(startOfDay(date.toDate()).toISOString());
          });
        }}
        selectedDate={startOfDay(new Date(selectedDate))}
        {...(Platform.OS === 'web' && { minDate: startOfDay(new Date()) })}
        datesBlacklist={date => {
          const dateObj = date.toDate();
          return isBefore(dateObj, startOfDay(new Date()));
        }}
      />
    </Box>
  );
};

const Item: FC<{ node: any; linkTo: any }> = ({ node, linkTo }) => {
  return (
    <Pressable
      // @ts-ignore
      onPress={() => linkTo({ screen: 'ClassSchedule', params: { scheduleId: node.id } })}
    >
      <Card
        style={{
          padding: 16,
          borderRadius: 8,
        }}
      >
        <VStack space={4}>
          <Heading size={'md'}>{node.gymClass.name}</Heading>

          <VStack>
            <Text>{node.gymClass.trainer.name}</Text>

            <HStack justifyContent={'space-between'}>
              <HStack space={3}>
                <Text>
                  {formatDuration(
                    intervalToDuration({
                      start: new Date(node.startedAt),
                      end: new Date(node.endedAt),
                    })
                  )}
                </Text>
              </HStack>

              <HStack space={2} alignItems={'center'}>
                <FontAwesomeIcon name={'clock-o'} />
                <Text>{`${format(new Date(node.startedAt), 'HH:mm')} - ${format(
                  new Date(node.endedAt),
                  'HH:mm'
                )}`}</Text>
              </HStack>
            </HStack>
          </VStack>
        </VStack>
      </Card>
    </Pressable>
  );
};
