import { Button, Divider, HStack } from 'native-base';
import React, { useState } from 'react';

import ScreenBackground from '~/components/ScreenBackground';
import BookingFlatListContainer from '~/containers/BookingFlatListContainer';

export default function ActivityScreen() {
  const [bookingStatus, setBookingStatus] = useState('UPCOMING');

  const inactiveProps = {
    variant: 'outline',
    _hover: { bg: 'transparent' },
    _pressed: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    _focusVisible: { bg: 'transparent' },
  };

  return (
    <ScreenBackground p={4}>
      <HStack space={4} mb={4}>
        <Button
          borderRadius={16}
          {...(bookingStatus !== 'UPCOMING' && inactiveProps)}
          onPress={() => setBookingStatus('UPCOMING')}
        >
          {'Upcoming'}
        </Button>
        <Button
          borderRadius={16}
          {...(bookingStatus !== 'PAST' && inactiveProps)}
          onPress={() => setBookingStatus('PAST')}
        >
          {'Past'}
        </Button>
      </HStack>

      <Divider />

      <BookingFlatListContainer bookingStatus={bookingStatus} />
    </ScreenBackground>
  );
}
