import { gql, useQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import * as Brightness from 'expo-brightness';
import { merge } from 'lodash';
import { Box, Heading, NativeBaseProvider, Skeleton, Spinner, Text, VStack } from 'native-base';
import React, { useCallback, useRef } from 'react';
import { Platform } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import ScreenBackground from '~/components/ScreenBackground';
import { theme as defaultTheme } from '~/constants/Theme';

export default function CheckInScreen() {
  const userScreenBrightness = useRef<number>();

  const { data = { currentMember: { id: '' } }, loading } = useQuery(
    gql`
      query GetCurrentMember {
        currentMember {
          uuid
        }
      }
    `
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        userScreenBrightness.current = await Brightness.getBrightnessAsync();
        Brightness.setBrightnessAsync(1);
      })();

      return () => {
        if (Platform.OS === 'android') {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          Brightness.useSystemBrightnessAsync();
        } else if (userScreenBrightness.current !== undefined) {
          Brightness.setBrightnessAsync(userScreenBrightness.current);
        }
      };
    }, [])
  );

  return (
    <NativeBaseProvider theme={merge(defaultTheme, { config: { initialColorMode: 'light' } })}>
      <ScreenBackground p={4}>
        <VStack flex={1} alignItems={'center'} justifyContent={'center'}>
          <Heading size={'lg'} fontWeight={'600'}>
            {'Scan your QR Code'}
          </Heading>

          {loading ? (
            <Skeleton width={256} height={4} mt={1} mb={8} />
          ) : (
            <Text mt={1} mb={8} fontSize={'xs'}>
              {`UUID: ${data.currentMember.uuid}`}
            </Text>
          )}

          {data.currentMember.uuid ? (
            <QRCode size={256} value={data.currentMember.uuid} />
          ) : (
            <Box width={256} height={256} justifyContent={'center'} alignItems={'center'}>
              <Spinner />
            </Box>
          )}
        </VStack>
      </ScreenBackground>
    </NativeBaseProvider>
  );
}
