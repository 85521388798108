import version from './version';

export default {
  apiUrl: 'https://api-rails-ur7ykybh7q-et.a.run.app',
  oAuthClientId: '-ykXiqqhTL-CGc1_x5mp_XCRWyn59BF1XtmAH0gStV8',
  sentryDsn: 'https://b7ac2b3094a44d34b35d314c22cd0f47@o1091418.ingest.sentry.io/6108853',
  sentryDebug: false,
  sentryEnvironment: 'production',
  version,
};
