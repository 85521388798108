import { gql, useQuery } from '@apollo/client';
import { VStack, FormControl, Avatar, Skeleton, Heading, Text, Divider, HStack } from 'native-base';
import React from 'react';

import FontAwesomeIcon from '~/components/FontAwesomeIcon';
import ScreenBackground from '~/components/ScreenBackground';
import { prependApiUrl } from '~/utils/urlUtil';

export default function AboutGymScreen() {
  const { loading, data = { currentGym: { name: '' } } } = useQuery(
    gql`
      query GetCurrentGym {
        currentGym {
          id
          name
          logoUrl
          address
          phoneNumber
          email
          instagram
          description
        }
      }
    `
  );

  return (
    <ScreenBackground safeAreaBottom>
      <VStack flex={1} space={4}>
        <VStack space={2} alignItems={'center'} mb={8} mt={4}>
          <Avatar
            {...(data.currentGym.logoUrl && {
              source: { uri: prependApiUrl(data.currentGym.logoUrl) },
            })}
          >
            {(data.currentGym.name as string)
              .split(' ')
              .map(n => n[0])
              .filter((_, i) => i < 2)
              .join('')
              .toUpperCase()}
          </Avatar>
          {loading ? (
            <Skeleton width={32} height={6} />
          ) : (
            <Heading size={'md'} selectable>
              {data.currentGym.name}
            </Heading>
          )}
        </VStack>

        <HStack>
          {loading ? (
            <Skeleton width={64} height={5} />
          ) : (
            <Text selectable>{data.currentGym.address}</Text>
          )}
        </HStack>

        <Divider />

        <HStack space={4} alignItems={'center'}>
          <FontAwesomeIcon name={'phone'} />
          {loading ? (
            <Skeleton width={32} height={5} />
          ) : (
            <Text selectable>{data.currentGym.phoneNumber}</Text>
          )}
        </HStack>

        <HStack space={4} alignItems={'center'}>
          <FontAwesomeIcon name={'envelope'} />
          {loading ? (
            <Skeleton width={32} height={5} />
          ) : (
            <Text selectable>{data.currentGym.email}</Text>
          )}
        </HStack>

        <HStack space={4} alignItems={'center'}>
          <FontAwesomeIcon name={'instagram'} />
          {loading ? (
            <Skeleton width={32} height={5} />
          ) : (
            <Text selectable>{data.currentGym.instagram}</Text>
          )}
        </HStack>

        <Divider />

        <FormControl>
          <FormControl.Label>{'About us'}</FormControl.Label>
          {loading ? (
            <Skeleton width={64} height={5} />
          ) : (
            <Text>{data.currentGym.description}</Text>
          )}
        </FormControl>
      </VStack>
    </ScreenBackground>
  );
}
