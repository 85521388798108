import { StackScreenProps } from '@react-navigation/stack';
import { Spinner } from 'native-base';
import React, { FC, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import { MainStackParamList } from '..';

import ScreenBackground from '~/components/ScreenBackground';

const MidtransSnapScreen: FC<StackScreenProps<MainStackParamList, 'MidtransSnap'>> = ({
  route,
}) => {
  const { redirectUrl, token, clientKey } = route.params;
  const [isLoading, setIsLoading] = useState(true);

  const snapJsUrl = redirectUrl.split('/').slice(0, 3).join('/') + '/snap/snap.js';

  const html = `
    <html style="height: 100%">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <script
          type="text/javascript"
          src="${snapJsUrl}"
          data-client-key="${clientKey}"></script>

        <script type="text/javascript">
          window.snap.pay('${token}', {
            onClose: () => window.snap.show()
          });
        </script>

        <style>
          .loader {
            margin: auto;
            border: 4px solid #f3f3f3;
            border-radius: 50%;
            border-top: 4px solid #b71c1c;
            width: 32px;
            height: 32px;
            -webkit-animation: spin 0.75s linear infinite; /* Safari */
            animation: spin 0.75s linear infinite;
          }

          /* Safari */
          @-webkit-keyframes spin {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        </style>
      </head>
      <body style="margin: 0px; height: 100%">
        <div style="display: flex; width: 100%; height: 100%;">
          <div class="loader"></div>
        </div>
      </body>
    </html>
  `;

  const openExternalLink = (req: any) => {
    if (
      // Gopay app link prefixes
      req.url.startsWith('https://gojek.link') ||
      req.url.startsWith('gojek://') ||
      // ShopeePay app link prefixes
      req.url.startsWith('https://wsa.wallet.airpay.co.id') ||
      req.url.startsWith('shopee://') ||
      // UOB EzPay app link prefixes
      req.url.startsWith('https://tmrwbyuobid.page.link') ||
      // other app link prefixes, if needed
      req.url.startsWith('intent://')
    ) {
      Linking.openURL(req.url); // URL will be opened on OS level, not by WebView
      return false; // prevent WebView from loading the URL
    }
    // URL doesn't meet the conditions to be handled specifically
    return true; // URL will be loaded via WebView normally
  };

  return (
    <>
      {isLoading && (
        <ScreenBackground p={4} justifyContent={'center'}>
          <Spinner />
        </ScreenBackground>
      )}

      <WebView
        style={{ display: isLoading ? 'none' : 'flex' }}
        source={Platform.OS === 'web' ? { html } : { uri: redirectUrl }}
        bounces={false}
        originWhitelist={['https://*', 'http://*', 'gojek://*', 'shopeeid://*']}
        allowFileAccess
        domStorageEnabled
        javaScriptEnabled
        geolocationEnabled
        saveFormDataDisabled
        allowFileAccessFromFileURLS
        allowUniversalAccessFromFileURLs
        onShouldStartLoadWithRequest={openExternalLink}
        onLoad={event => setIsLoading(event.nativeEvent.loading)}
      />
    </>
  );
};

export default MidtransSnapScreen;
