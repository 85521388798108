import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, FlatList, Skeleton, Text, useColorModeValue, VStack } from 'native-base';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatListProps } from 'react-native';

import NotificationItem from '~/components/NotificationItem';
import ScreenBackground from '~/components/ScreenBackground';
import useExpoNotifications from '~/hooks/useExpoNotifications';
import * as Sentry from '~/utils/sentry';

export default function NotificationsScreen() {
  const [alreadySeen, setAlreadySeen] = useState<Record<number, any>>({});
  const alreadyRead = useRef(new Set());

  const bgColor = useColorModeValue('gray.50', 'dark.100');

  useExpoNotifications();

  const { loading, refetch, data, fetchMore, networkStatus } = useQuery(
    gql`
      query GetNotifications($cursor: String) {
        notifications(after: $cursor) {
          edges {
            node {
              id
              type
              readAt
              waitingListNotificationParams {
                booking {
                  schedule {
                    id
                    gymClass {
                      name
                    }
                    startedAt
                    endedAt
                  }
                }
              }
              willExpireTransactionNotificationParams {
                transaction {
                  membershipPackage {
                    name
                    membership {
                      name
                    }
                  }
                }
              }
              createdAt
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const [readNotifications] = useMutation(
    gql`
      mutation ReadNotifications($input: ReadNotificationsInput!) {
        readNotifications(input: $input) {
          success
          ids
          errors
        }
      }
    `,
    {
      onCompleted: result => {
        if (result.readNotifications.success) {
          alreadyRead.current = new Set([...alreadyRead.current, result.readNotifications.ids]);
        } else {
          const errors = result.readNotifications.errors;
          Sentry.captureAnyMessage(errors);
        }
      },
      onError: error => {
        Sentry.captureException(error);
      },
      refetchQueries: ['GetUnreadNotificationsCount'],
    }
  );

  const handleReadNotifications = useCallback(
    async (ids: number[]) => {
      readNotifications({
        variables: {
          input: {
            ids,
          },
        },
      });
    },
    [readNotifications]
  );

  useEffect(() => {
    const unreadNotificationIds = Object.values(alreadySeen)
      .filter(({ id, readAt }) => !readAt && !alreadyRead.current.has(id))
      .map(({ id }) => id);

    if (unreadNotificationIds.length > 0) {
      handleReadNotifications(unreadNotificationIds);
    }
  }, [alreadySeen, handleReadNotifications]);

  return (
    <ScreenBackground p={0} py={4}>
      <FlatList
        data={data?.notifications?.edges}
        ItemSeparatorComponent={() => <Box pt={4} />}
        showsVerticalScrollIndicator={false}
        keyExtractor={({ node: { id } }) => id}
        renderItem={({ item: { node } }) => (
          <NotificationItem item={node} onPress={notification => notification} />
        )}
        onRefresh={() => refetch()}
        refreshing={loading}
        onEndReached={() => {
          if (data?.notifications?.pageInfo?.hasNextPage) {
            fetchMore({
              variables: {
                cursor: data?.notifications?.pageInfo?.endCursor,
              },
            });
          }
        }}
        ListFooterComponent={() => {
          return loading ? (
            <Skeleton
              // networkStatus === 2 is a fetchMore event
              mt={networkStatus === 3 ? 4 : 0}
              mx={4}
              height={20}
              // @ts-ignore
              variant={'rect'}
              bgColor={bgColor}
              borderRadius={8}
            />
          ) : null;
        }}
        ListEmptyComponent={() =>
          loading ? null : (
            <VStack space={4} justifyContent={'center'} alignItems={'center'}>
              <Text>{'No notifications'}</Text>
            </VStack>
          )
        }
        onViewableItemsChanged={useCallback(
          (info: Parameters<NonNullable<FlatListProps<any>['onViewableItemsChanged']>>[0]) => {
            const visibleItems = info.changed.filter(entry => entry.isViewable);

            setAlreadySeen(prevState => {
              visibleItems.forEach(visible => {
                const exists = visible.item.node.id in prevState;
                if (!exists) {
                  prevState[visible.item.node.id] = visible.item.node;
                }
              });

              return { ...prevState };
            });
          },
          []
        )}
        viewabilityConfig={{
          itemVisiblePercentThreshold: 75,
          minimumViewTime: 500,
        }}
      />
    </ScreenBackground>
  );
}
