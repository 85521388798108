import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageManager, ColorMode } from 'native-base';
import { useMemo } from 'react';

import useColorScheme from './useColorScheme';

import * as Sentry from '~/utils/sentry';

const useColorModeManager = () => {
  const colorScheme = useColorScheme();

  // Define the colorModeManager,
  // here we are using react-native-async-storage (https://react-native-async-storage.github.io/async-storage/)
  const colorModeManager: StorageManager = useMemo(
    () => ({
      get: async () => {
        try {
          const val = await AsyncStorage.getItem('@color_mode');

          if (val) {
            return val === 'dark' ? 'dark' : 'light';
          } else {
            return colorScheme;
          }
        } catch (e) {
          Sentry.captureException(e);
          return colorScheme;
        }
      },
      set: async (value: NonNullable<ColorMode>) => {
        try {
          await AsyncStorage.setItem('@color_mode', value);
        } catch (e) {
          Sentry.captureException(e);
          console.log(e);
        }
      },
    }),
    [colorScheme]
  );

  return colorModeManager;
};

export default useColorModeManager;
