import { gql, useQuery } from '@apollo/client';
import { Box, FlatList, Skeleton, Text, useColorModeValue, useDisclose, VStack } from 'native-base';
import React, { useState } from 'react';

import ScreenBackground from '~/components/ScreenBackground';
import TransactionItem from '~/components/TransactionItem';
import TransactionItemDetailSheet from '~/components/TransactionItemDetailSheet';

export default function TransactionScreen() {
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});
  const { isOpen, onOpen, onClose } = useDisclose();
  const bgColor = useColorModeValue('gray.50', 'dark.100');

  const { loading, refetch, data, fetchMore, networkStatus } = useQuery(
    gql`
      query GetTranactions($cursor: String) {
        transactions(after: $cursor) {
          edges {
            node {
              id
              paidAt
              category
              membershipPackageName
              membershipName
              unlimitedSession
              trainingSession
              bookingUsed
              periodDay
              trainer {
                id
                name
              }
              gymClass {
                id
                name
                trainer {
                  id
                  name
                }
                capacity
              }
              payment {
                id
              }
              price
              startedAt
              endedAt
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  return (
    <ScreenBackground p={4}>
      <FlatList
        data={data?.transactions?.edges}
        ItemSeparatorComponent={() => <Box pt={4} />}
        showsVerticalScrollIndicator={false}
        keyExtractor={({ node: { id } }) => id}
        renderItem={({ item: { node } }) => (
          <TransactionItem
            item={node}
            onPress={transaction => {
              setSelectedTransaction(transaction);
              onOpen();
            }}
          />
        )}
        onRefresh={() => refetch()}
        refreshing={loading}
        onEndReached={() => {
          if (data?.transactions?.pageInfo?.hasNextPage) {
            fetchMore({
              variables: {
                cursor: data?.transactions?.pageInfo?.endCursor,
              },
            });
          }
        }}
        ListFooterComponent={() => {
          return loading ? (
            <Skeleton
              // networkStatus === 2 is a fetchMore event
              mt={networkStatus === 3 ? 4 : 0}
              height={20}
              // @ts-ignore
              variant={'rect'}
              bgColor={bgColor}
              borderRadius={8}
            />
          ) : null;
        }}
        ListEmptyComponent={() =>
          loading ? null : (
            <VStack space={4} justifyContent={'center'} alignItems={'center'}>
              <Text>{'No transactions'}</Text>
            </VStack>
          )
        }
      />

      <TransactionItemDetailSheet item={selectedTransaction} isOpen={isOpen} onClose={onClose} />
    </ScreenBackground>
  );
}
