import { format } from 'date-fns';
import { Actionsheet, Divider, Text, VStack } from 'native-base';
import React, { FC } from 'react';
import { Dimensions, Platform } from 'react-native';

import { trainingCategoryValueLabelMap } from '~/constants/options';
import { useMobileWebStyle } from '~/navigation';
import { formatCurrency } from '~/utils/numberUtil';

const TransactionItemDetailSheet: FC<{ item: any; isOpen: boolean; onClose: () => void }> = ({
  item,
  isOpen,
  onClose,
}) => {
  const webStyle = useMobileWebStyle();

  return (
    <Actionsheet
      isOpen={isOpen}
      onClose={onClose}
      size={'full'}
      {...(Platform.OS === 'web'
        ? {
            width: Math.min(webStyle.maxWidth as number, Dimensions.get('window').width),
            marginX: webStyle.marginHorizontal,
          }
        : {})}
    >
      <Actionsheet.Content>
        <VStack w={'100%'} px={4}>
          <VStack>
            <Text fontWeight={'bold'}>{'Membership:'}</Text>
            <Text>{item.membershipName}</Text>
          </VStack>

          <Divider my={2} />

          <VStack>
            <Text fontWeight={'bold'}>{'Package:'}</Text>
            <Text>{item.membershipPackageName}</Text>
          </VStack>

          <Divider my={2} />

          <VStack>
            <Text fontWeight={'bold'}>{'Price:'}</Text>
            <Text>{formatCurrency(item.price)}</Text>
          </VStack>

          <Divider my={2} />

          <VStack>
            <Text fontWeight={'bold'}>{'Category:'}</Text>
            {/** @ts-ignore */}
            <Text>{trainingCategoryValueLabelMap[item.category]}</Text>
          </VStack>

          {item.trainer?.name && (
            <>
              <Divider my={2} />

              <VStack>
                <Text fontWeight={'bold'}>{'Trainer:'}</Text>
                <Text>{item.trainer?.name}</Text>
              </VStack>
            </>
          )}

          {item.gymClass?.name && (
            <>
              <Divider my={2} />

              <VStack>
                <Text fontWeight={'bold'}>{'Class:'}</Text>
                <Text>{item.gymClass?.name}</Text>
              </VStack>
            </>
          )}

          <Divider my={2} />

          <VStack>
            <Text fontWeight={'bold'}>{'Credits (Used):'}</Text>
            <Text>
              {item.unlimitedSession ? '∞' : item.trainingSession} {`(${item.bookingUsed})`}
            </Text>
          </VStack>

          {item.startedAt && item.endedAt && (
            <>
              <Divider my={2} />

              <VStack>
                <Text fontWeight={'bold'}>{'Period:'}</Text>
                <Text>
                  {format(new Date(item.startedAt), 'dd MMM yyyy')}
                  {' - '}
                  {format(new Date(item.endedAt), 'dd MMM yyyy')}
                </Text>
              </VStack>
            </>
          )}

          <Divider my={2} />

          <VStack>
            <Text fontWeight={'bold'}>{'Payment ID:'}</Text>
            <Text selectable>{item.payment?.id}</Text>
          </VStack>
        </VStack>
      </Actionsheet.Content>
    </Actionsheet>
  );
};

export default TransactionItemDetailSheet;
